import { Injectable } from '@angular/core';

@Injectable()
export class Converter {
    constructor() { }

    ConvertValue(tempData: any, AttributeTypeId: string): any {
        if (AttributeTypeId === 'int') {
            return parseInt(tempData);
        }
        else if (AttributeTypeId === 'bool') {
            return tempData === 'true'
        }
        else if (AttributeTypeId === 'string') {
            return tempData;
        }
    }
}