<div [ngClass.gt-sm]="['mr-6']" class="card overflow-auto ml-5" fxLayout="row" fxLayout.lt-md="column">
  <div class="p-gutter" fxFlex.lt-md="auto">
    <div class="mt-1" gdGap="5px">
      <ng-container *ngFor="let navItem of navigationItems">
        <a (click)="close()" *ngIf="navItem.type=='link'" [routerLink]="navItem.route"
          class="text-dark p-3 text-center hover:bg-hover hover:text-primary trans-ease-out rounded block no-underline">
          <ic-icon [icon]="dashboardIcon" class="text-primary" size="32px"></ic-icon>
          <div class="body-1 mt-2">{{ navItem.label }}</div>
        </a>
      </ng-container>
    </div>
  </div>
  <ng-container *ngFor="let navItem of navigationItems" class="overflow-auto">
    <div class="p-gutter" fxFlex.lt-md="auto" *ngIf="navItem.type=='dropdown'">
      <h3 class="body-2 m-0" style="font-weight: 800;">{{navItem.label}}</h3>
      <div class="mt-5" gdGap="16px">
        <ng-container *ngFor="let page of navItem.children">
          <a *ngIf="!page.children; else label" (click)="close()" [routerLink]="page.route" class="text-dark body-1 no-underline hover:text-primary trans-ease-out">
            {{ page.label }}
          </a>
          <ng-template #label>
            <h3 class="body-2 m-0" style="font-weight: 800;">{{page.label}}</h3>
          </ng-template>
          <ng-container [ngTemplateOutletContext]="{ item: page.children }" [ngTemplateOutlet]="linkTemplate"></ng-container>
          </ng-container>

      </div>
    </div>
  </ng-container>
</div>

<ng-template #linkTemplate let-item="item">
  <ng-container *ngFor="let page of item">
    <a *ngIf="!page.children; else labelChild" (click)="close()" [routerLink]="page.route" class="pl-4 text-dark body-1 no-underline hover:text-primary trans-ease-out">
      {{ page.label }}
    </a>
    <ng-template #labelChild>
      <h3 class="body-2 m-0" style="font-weight: 800;">{{page.label}}</h3>
    </ng-template>
    <ng-container [ngTemplateOutletContext]="{ item: page.children }" [ngTemplateOutlet]="linkTemplate"></ng-container>
  </ng-container>
</ng-template>