import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { CodeEditorModule } from '@ngstack/code-editor';
import { Converter } from '@vex/helper/functions/converter';
import { InputControl } from '@vex/utils/input-control';
import { VexModule } from '@vex/vex.module';
import { ToastrModule } from 'ngx-toastr';
import { HttpCancelService } from '../@vex/http-interceptor/http-cancel.service';
import { AuthInterceptor } from '../@vex/http-interceptor/auth.interceptor';
import { DatePipe } from '@angular/common';
import { DateFormatModule } from '@vex/pipes/date-format/date-format.module';
import { PushNotificationsService } from '@vex/services/push-notifications.service';
import { MatSelectModule } from '@angular/material/select';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {
    IPublicClientApplication,
    PublicClientApplication,
    InteractionType,
    BrowserCacheLocation,
    LogLevel
} from '@azure/msal-browser';
import {
    MsalGuard,
    MsalInterceptor,
    MsalBroadcastService,
    MsalInterceptorConfiguration,
    MsalModule,
    MsalService,
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalGuardConfiguration,
    MsalRedirectComponent
} from '@azure/msal-angular';
import { CookieService } from '@vex/services/cookie.service';
const isIE =
    window.navigator.userAgent.indexOf('MSIE ') > -1 ||
    window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
    if(message.includes('in acquireToken call')) {
        localStorage.setItem('isADLogin', 'true');
    }
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.CLIENT_ID,
            authority: 'https://login.microsoftonline.com/' + environment.TENANT_ID,
            redirectUri: environment.CLIENT_URL + 'login/'
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE // set to true for IE 11
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false
            }
        }
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['user.read']
        }
    };
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ToastrModule.forRoot({
            preventDuplicates: true,
            resetTimeoutOnDuplicate: true,
            closeButton: true
        }),
        CustomLayoutModule,
        RouterModule,
        DateFormatModule,
        VexModule,
        // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        MsalModule,
        MatSelectModule,
        CodeEditorModule.forRoot()
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
        { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
        { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        HttpCancelService,
        DatePipe,
        InputControl,
        Converter,
        PushNotificationsService,
        CookieService
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
