// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: true,
    googleMapsApiKey:"",
    API_URL:"https://phinia-api.kumorai.io/api/",
    WORKER_URL:"https://phinia-worker.kumorai.io/",
    CLIENT_URL:"https://phinia-portal.kumorai.io/",
    CLIENT_ID:"647a63b8-96c7-49d9-8924-c1b92baf075a",
    TENANT_ID:"5091ee51-2b4b-4d7d-9964-666621ef0688",
    FILE_NAME:"production-environment", 
    CLIENT_NAME: 'Phinia'
};
